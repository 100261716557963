
import { registerApplication, start,getRawAppData,getAppNames,unloadApplication} from "single-spa";

registerApplication(
    "@qms/qms-navbar",
   () => System.import("@qms/qms-navbar"),
    location => true
);

registerApplication(
    "@qms/qms-core",
    () => System.import("@qms/qms-core"),
    location => location.pathname.startsWith('/sys'),
);
registerApplication(
    "@qms/qms-btob",
    () => System.import("@qms/qms-btob"),
    location => location.pathname.startsWith('/b2b') || location.pathname.startsWith('/contact'),
);

window.registerApplication = registerApplication;
start();

function checkIsLoadedCompleted(){
    if(window.itmLoaderCheckInterval)
        clearInterval(window.itmLoaderCheckInterval);
    window.itmLoaderCheckInterval = setInterval(() => {
        if(document.querySelectorAll('.router-transitions > div:empty').length != document.querySelectorAll('.router-transitions > div').length){
            document.body.classList.remove('app-loading');
            clearInterval(window.itmLoaderCheckInterval);
        }
    },100);
}
setTimeout(function() {
window.addEventListener('single-spa:routing-event', evt => {
    var force_reload = false;
    if(document?.body?.children){
	Array.from(document.body.children).forEach(a => { if(a.id.startsWith('single-spa-application:@qms/') && a.id != 'single-spa-application:@qms/qms-navbar'){ a.remove();force_reload = true;} });
	if(force_reload){
	     let apps = getAppNames();
	     apps.filter(b => b.indexOf('navbar') == -1).forEach((a => unloadApplication(a)));
	}
    }
    document.body.classList.add('app-loading');
    
    checkIsLoadedCompleted();
})},100);